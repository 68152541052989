import React, { useEffect, useState } from 'react';
import { getJobCategories } from '../../api.controller';
import { Link } from 'react-router-dom';
import './JobCategoriesSection.css';


function Feature() {
    const [categories, setCategories] = useState([]);
    const _getJobCategories = async () => {
        try {
            const response = await getJobCategories();
            console.log(response);
            setCategories(response?.data?.docs || []);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        _getJobCategories();
    }, []);
    return (
        <div>
            <section className="feature-one">
                <div className="container">
                    <h3>Browse Jobs By Category</h3>
                    <div className="row mt-5">
                        {categories.map(category => {
                            return <div className="col-lg-4 col-md-6">
                                <Link to={`/jobs?category=${category._id ?? "Others"}`}>
                                    <div className="bg-light category  p-4 rounded border mb-3">
                                        <h6>{category._id ?? "Others"}</h6>
                                        <h3 className="fw-light ">{category.jobCount}</h3>
                                    </div>
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Feature;
